import React from "react"
import { Helmet } from "react-helmet";
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import ExpertHelp from "../components/ExpertHelp"
import { SectionPage, Container, SectionPageTitle, } from "../components/Section"
import HeroBanner from "../components/HeroBanner"
import ReviewSliderWidget from "../components/ReviewSliderWidget"
import EstimateFrom from "../components/EstimateForm"
import TabProductCardList from "../components/TabProductCardList"
import Service from "../components/Service"

const LandingPageTemplate = ({ data, location }) => {

  const pageData = data.contentfulLandingPage;
  const products = data.allContentfulProduct.edges;

  const TestimonialSectionData = data.contentfulHome.reviewSection
  const TestimonialData = data.allContentfulTestimonial

  const renderProductList = () => {
    return (
      <TabProductCardList
        data={products}
        category={pageData.productCategory.name}
      />
    )
  }

  return (
    <Layout location={location} isPpc>
      <Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <HeroBanner
        location={location}
        title={pageData.pageTitle}
        subTitle={pageData.pageDescription}
        backgroundImageUrl={pageData.background.file.url}
        backgroundImageAlt={pageData.backgroundAlt}
      />
      <SectionPage ept="60px" epb="60px" xpt="40px" xpb="40px" pt="30px" pb="30px" bg="#F4FBFF">
        <Container>
          <SectionPageTitle textAlign="center">Find the Perfect Metal Building for Your Needs</SectionPageTitle>
          {renderProductList()}
        </Container>
      </SectionPage>
      <EstimateFrom location={location} />
      <Service />
      <ExpertHelp />
      <ReviewSliderWidget sectionData={TestimonialSectionData} data={TestimonialData} />
      <CallToAction />
    </Layout>
  )
}

export default LandingPageTemplate

export const pageQuery = graphql`
  query LandingPageQuery($id: String!, $productCategoryName: String!) {

    contentfulLandingPage(id: {eq: $id}) {
      id
      metaDescription
      metaTitle
      pageTitle
      pageDescription
      background {
        file {
          url
        }
      }
      backgroundAlt
      productCategory {
        name
      }
    }

    allContentfulProduct(
      filter: {productCategory: {name: {eq: $productCategoryName}}}
    ) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          description {
            description
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }


    allContentfulTestimonial {
      edges {
        node {
          customerName
          description {
            description
          }
        }
      }
    }

    contentfulHome {
      reviewSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
